import { render, staticRenderFns } from "./CourseCuration.vue?vue&type=template&id=61be63bc&scoped=true"
import script from "./CourseCuration.vue?vue&type=script&lang=js"
export * from "./CourseCuration.vue?vue&type=script&lang=js"
import style0 from "./CourseCuration.vue?vue&type=style&index=0&id=61be63bc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61be63bc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CourseItem: require('/codebuild/output/src3377776947/src/components/course/course-item/CourseItem.vue').default})
